import React, {useEffect, useRef} from "react";
import YouTube from "react-youtube";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";


const Live = ({ids}) => {

    console.log(ids);

    function getRandomIndex(array) {
        var maxIndex = array.length;
        var randomIndex = Math.floor(Math.random() * maxIndex);
        return randomIndex;
    }

    return (<div id={"live"} className={"live__outer"}>
        <div className={"live__slide"}>
            <div className={"live__inner"}>
                <div className="live-video">
                    <YouTube className="" videoId={ids[0]}/>
                </div>
            </div>
        </div>
    </div>)
}


export default Live
