import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import Mitglied from "./Mitglied";

const Band = ({image, mitglieder}) => {

    return (
        <div className={"band__outer"}>
            <div className={"band__inner"}>
                <GatsbyImage image={image} alt={"Fleur de Nuit Band"}></GatsbyImage>
                <div className="bandmitglieder">
                    {mitglieder.map(el => (
                        <Mitglied el={el}></Mitglied>
                    ))}
                </div>
            </div>
        </div>
    )
}


export default Band
