import React, {useRef} from "react";
import axios from 'axios';


const Kontakt = ({instagram, facebook}) => {

    const [valueKontakt, setValueKontakt] = React.useState({})

    let serverResponse = useRef();

    function handleChange(e){
        valueKontakt[e.target.id] = e.target.value;
        serverResponse.current.style.color = "#000000";
        serverResponse.current.innerText = "";
        setValueKontakt({...valueKontakt});
    }

    async function onSubmitKontakt(e) {
        e.preventDefault()
        if(valueKontakt.kontaktname && valueKontakt.kontaktemail){

            valueKontakt.kontaktnachricht = document.querySelector('#kontaktnachricht').innerText;

            var params = new URLSearchParams();
            params.append('name', valueKontakt.kontaktname);
            params.append('email', valueKontakt.kontaktemail);
            params.append('nachricht', valueKontakt.kontaktnachricht);

            axios.post('https://api.fleurdenuit.at/form.php', params)
                .then(function (response) {
                    serverResponse.current.style.color = "#64C028";
                    serverResponse.current.innerText = "Nachricht wurde abgeschickt!";
                    console.log(response);
                    setValueKontakt({});
                    document.querySelector('#kontaktnachricht').innerText = "";
                })
                .catch(function (error) {
                    serverResponse.current.style.color = "#F05353";
                    serverResponse.current.innerText = "Es gab einen Fehler beim Absenden der Nachricht. Bitte versuche es erneut.";
                    console.log(error);
                });
        }
        else {
            serverResponse.current.innerText = "Bitte fülle alle Pflichtfelder aus!";
            serverResponse.current.style.color = "#F05353";
        }
    }



    return (
        <div className={"kontakt__outer"} id={"kontakt"}>
            <div className={"kontakt__inner"}>
                <h2>Kontakt</h2>
                <a href="emailto:kontakt@fleurdenuit.at" className={"hover-moveup"}><p className={"clash"}>kontakt@fleurdenuit.at</p></a>
                <div className="kontakt__form__outer">
                    <form onSubmit={onSubmitKontakt} className={"kontakt__form"} method={"post"} action={"/api/form.php"}>
                        <label className="kontakt__label">
                            <p className="clash small ">*Name:</p>
                            <input type="text" name={"kontaktname"} className={"kontakt__input"} id={"kontaktname"} value={valueKontakt[`kontaktname`] || ``} onChange={handleChange} />
                        </label>
                        <label className="kontakt__label">
                            <p className="clash small ">*E-Mail:</p>
                            <input type="email" name="kontaktemail" className="kontakt__input" id="kontaktemail" value={valueKontakt[`kontaktemail`] || ``} onChange={handleChange}/>
                        </label>
                        <label className="kontakt__label">
                            <p className="clash small ">*Nachricht:</p>
                            <span className="textarea kontakt__input clash" id="kontaktnachricht" role="textbox" contentEditable></span>
                        </label>
                        <p className="small serverresponse clash" ref={serverResponse}></p>
                        <div className="kontakt__btn__container">
                            <button type="submit" value="Absenden" className="btn bg-dark color-light clash">
                                Absenden
                            </button>
                        </div>
                    </form>
                </div>
                <p className="clash small">Oder sende uns direkt eine <a href="/buchen/daten" className={"underline"}>Buchungsanfrage</a>.</p>


                <div className="sociallinks">
                    <a href={facebook} target={"_blank"}>
                        <p className="clash uppercase underline">Facebook</p>
                    </a>
                    <a href={instagram} target={"_blank"}>
                        <p className="clash uppercase underline">Instagram</p>
                    </a>
                </div>
                <br/>
            </div>
        </div>
    )
}


export default Kontakt

//                <p className="clash">Oder sende uns direkt eine <a href="/buchungsanfrage" className={"underline"}>Buchungsanfrage</a>.</p>
