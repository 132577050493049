import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

const LiveBilder = ({images}) => {

    return (
        <div className={"liveimages__outer"}>
            <div className={"liveimages__inner"}>
                {images.map(el => (
                    <GatsbyImage image={el.asset.gatsbyImageData} alt={"Fleur de Nuit Live"}></GatsbyImage>
                ))}
            </div>
        </div>
    )
}


export default LiveBilder
