import React, {useEffect, useRef} from "react";
import Icon from "./Icon";
import {GatsbyImage} from "gatsby-plugin-image";

const Mitglied = ({el}) => {

    return (
        <div className="mitglied">
            <GatsbyImage image={el.image.asset.gatsbyImageData} alt={el.name}></GatsbyImage>
            <div className="name clash">{el.name}</div>
            <p className="instrument">{el.instrument}</p>
            <div className="socials">
                {el.fb ? <a href={el.fb}><Icon icon={"facebook"}></Icon></a> : <></>}
                {el.insta ? <a href={el.fb}><Icon icon={"instagram"}></Icon></a> : <></>}
                {el.linkedin ? <a href={el.fb}><Icon icon={"linkedin"}></Icon></a> : <></>}
                {el.website ? <a href={el.fb}><Icon icon={"web"}></Icon></a> : <></>}
                {el.spotify ? <a href={el.fb}><Icon icon={"spotify"}></Icon></a> : <></>}
            </div>
        </div>
    )
}


export default Mitglied
