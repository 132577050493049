import React, {useRef, useState} from "react";
import MyPortableText from "./MyPortableText";

const FAQ = ({el}) => {

    const [open, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!open);
    };

    const contentRef = useRef();

    return (
        <div className={"faq"}>
            <div className="frage" onClick={toggle}>
                <p className="large clash">{el.frage}</p>
                <svg className={open ? "rotated" : ""} xmlns="http://www.w3.org/2000/svg" width="28.957" height="28.957" viewBox="0 0 28.957 28.957">
                    <g id="Group_34" data-name="Group 34" transform="translate(-1463.34 -5766.521)">
                        <path id="Path_35" data-name="Path 35" d="M20454.818,4467.734v28.957" transform="translate(-18977 1298.788)" fill="none" stroke="#000" stroke-width="1"/>
                        <path id="Path_36" data-name="Path 36" d="M0,0V28.957" transform="translate(1492.297 5781) rotate(90)" fill="none" stroke="#000" stroke-width="1"/>
                    </g>
                </svg>
            </div>
            <div className="collapsible" ref={contentRef} style={open ? {height: contentRef.current.scrollHeight + "px"} : {height: "0px"}}>
                <div className="antwort">
                    <MyPortableText value={el._rawAntwort}></MyPortableText>

                </div>
            </div>
        </div>
    )
}


export default FAQ
