import React from "react";
import MyPortableText from "./MyPortableText";

const Intro = ({introleft, headline, introright}) => {


    return (
        <div id={"about"} className={"introtext"}>
            <h1>{headline}</h1>
            <div className="introtext__content">
                <div>
                    <MyPortableText value={introleft}></MyPortableText>
                </div>
                <div>
                    <MyPortableText value={introright}></MyPortableText>

                </div>
            </div>
        </div>
    )
}


export default Intro
