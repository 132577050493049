import React from "react";
import FAQ from "./FAQ";

const FAQs = ({faqs}) => {

    return (
        <div id={"faqs"} className={"faqs__outer"}>
            <div className={"faqs__inner"}>
                <h2>FAQs</h2>
                <div className="faqs__container">
                    {faqs.map(el => (
                        <FAQ el={el}></FAQ>
                    ))}
                </div>
            </div>
        </div>
    )
}


export default FAQs
