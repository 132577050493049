import * as React from "react"
import Layout from "../components/Layout";
import { graphql } from 'gatsby';
import Intro from "../components/Intro";
import Band from "../components/Band";
import Live from "../components/Live";
import Testimonial from "../components/Testimonial";
import LiveBilder from "../components/LiveBilder";
import Repertoire from "../components/Repertoire";
import FAQs from "../components/FAQs";
import Kontakt from "../components/Kontakt";
import SEO from "../components/SEO";
import YouTube from "react-youtube";

export const query = graphql`
    {
    allSanitySettings{
        edges {
      node {
        addresszeile1
        addresszeile2
        addresszeile3
        kontaktemail
        telefon
        instalink
        fblink
      }
    }
    }
    
    allSanityStartseite {
    edges {
      node {
       text
       _rawAbouttextright
       _rawAbouttextleft
       aboutheadline
        image {
          asset {
            gatsbyImageData
          }
        }
        imagemobile {
          asset {
            gatsbyImageData
          }
        }
        bandimage {
          asset {
            gatsbyImageData
          }
        }
        bandmitglied {
          fb
          image {
            asset {
              gatsbyImageData
            }
          }
          insta
          instrument
          linkedin
          name
          spotify
          website
        }
        songtext
          livevid
        testimonials {
          von
          text
        }
        liveimages {
          asset {
            gatsbyImageData
          }
        }
        albumcovers {
          asset {
            gatsbyImageData
          }
        }
        faqs {
          frage
          _rawAntwort
        }
      }
    }
  }
  site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
  }
`

const IndexPage = ({data}) => {

    const page = data.allSanityStartseite.edges[0].node;

    console.log(page);


    function getRandomIndex(array) {
        var maxIndex = array.length;
        var randomIndex = Math.floor(Math.random() * maxIndex);
        return randomIndex;
    }

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            controls: 1,
            playsinline: 1,
            disablekb: 1,
            playlist: page.livevid[0],
        },
    };

    return (
        <Layout settings={data.allSanitySettings.edges[0].node}  marquee={page.text} live={page.livevid ? true : false} repertoire={page.albumcovers ? true : false} image={page.image} mobileimage={page.imagemobile}>
            <SEO />
            <Intro introleft={page._rawAbouttextleft} headline={page.aboutheadline} introright={page._rawAbouttextright}></Intro>
            <Band image={page.bandimage.asset.gatsbyImageData} mitglieder={page.bandmitglied}></Band>
            <div id={"live"} className={"live__outer"}>
            {page?.livevid?.map((vid, index) => {
                return <div key={index} className={"live__slide"}>
                    <div className={"live__inner"}>
                        <div className="live-video">
                            <YouTube className="" videoId={vid}/>
                        </div>
                    </div>
                </div>
            })}
            </div>
            <Testimonial testimonials={page.testimonials}></Testimonial>
            {page.liveimages.length > 0 ? <LiveBilder images={page.liveimages}></LiveBilder> : <></>}
            {page.albumcovers.length > 0 ? <Repertoire  images={page.albumcovers} songText={page.songtext}></Repertoire> : <></>}
            <FAQs  faqs={page.faqs}></FAQs>
            <Kontakt  instagram={data.allSanitySettings.edges[0].node.instalink} facebook={data.allSanitySettings.edges[0].node.fblink}></Kontakt>
        </Layout>
    )
}

export default IndexPage
