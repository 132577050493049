import React from "react";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const Testimonial = ({testimonials}) => {

    return (
        <div className={"testimonial__outer"}>
            <div className={"testimonial__inner"}>
                <Swiper
                    className="mySwiper"
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}>
                    {testimonials.map((el) => {
                        return (
                            <SwiperSlide className={"testimonial__slide"}>
                                <h4>{el.text}</h4>
                                <p className={"testimonialauthor"}>- {el.von}</p>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    )
}


export default Testimonial
