import React, {useEffect, useRef} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import Marquee from "react-fast-marquee";

const Repertoire = ({images, songText}) => {

    let songRef = useRef();

    let songContainerRef = useRef();

    function repertoireAnfrage(){
        console.log(songRef.current.value);
        document.getElementById("kontakt").scrollIntoView();
        document.getElementById('kontaktnachricht').innerText = songText + ' ' + songRef.current.value + '?';
    }

    useEffect(() => {
        //personaAnimation(songContainerRef.current);
    })

    return (
        <div id={"repertoire"} className={"repertoire__outer"}>
            <div className={"repertoire__inner"}>
                <h2>Repertoire</h2>
                <Marquee gradient={false}>
                    {images.map(el => (
                        <GatsbyImage image={el.asset.gatsbyImageData} alt={"Repertoire"}></GatsbyImage>
                    ))}
                    {images.map(el => (
                        <GatsbyImage image={el.asset.gatsbyImageData} alt={"Repertoire"}></GatsbyImage>
                    ))}
                </Marquee>
                <p className={""}>Und viele mehr...</p>
                <div ref={songContainerRef}>
                    <p className="large clash">Du hast einen bestimmten Songwunsch?</p>
                    <input type="text" className={"clash"} placeholder={"Songname"} ref={songRef}/>
                    <div className="btn clash bg-dark color-light" onClick={repertoireAnfrage}>Anfragen</div>
                </div>
            </div>
        </div>
    )
}


export default Repertoire


/*

 */
